<template>
  <div>
    <div
      :style="{ position: 'relative', height: `${height}px`, width: '100%' }"
    >
      <l-map
        ref="refMap"
        :inertia="true"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        style="height: 100%"
      >
        <l-tile-layer :url="url" :attribution="attribution" />

        <l-marker
          v-for="marker in details.markers"
          :key="marker.id"
          :lat-lng="marker.coordinates"
        >
          <l-popup>
            <div v-html="marker.text"></div>
          </l-popup>
        </l-marker>
      </l-map>
    </div>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";

export default {
  name: "geo-map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },

  props: ["details", "show"],

  data() {
    return {
      url: this.details.url,
      attribution: "FRISS",
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false,
      },
      showMap: true,
    };
  },

  computed: {
    center() {
      return latLng(+this.details.coordinates[0], +this.details.coordinates[1]);
    },

    zoom() {
      return +this.details.zoom;
    },

    height() {
      return this.details.height ? this.details.height : 400;
    },
  },

  methods: {},

  watch: {
    show(val) {
      if (val) {
        this.$refs.refMap.mapObject.invalidateSize();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
</style>